import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import * as ColorUtils from '../components/color-utils'

import lifesAFestLogo from '../img/lifes-a-fest-logo.svg'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

// eslint-disable-next-line
export const IndexPageTemplate = class extends React.Component {

	componentDidMount() {
		ColorUtils.setLightColor();
	}

	render() {
		return (
			<div className="container-xxl container-padding">
				<img className="fest-logo" src={lifesAFestLogo} alt="Life's a Fest" title="Life's a Fest"/>
				<div className="image-wrapper">
					<div className="quote-image-wrapper">
						<PreviewCompatibleImage festClassName="fest-image" imageInfo={this.props.data.image1} />
						<div className="fest-image-quote mb-sm-5">Fun <span>comes first</span></div>
					</div>
					<div className="quote-image-wrapper">
						{/* <img className="fest-image-quote" src={quote} alt="Look good, feel good." title="Look good, feel good."/> */}
						<PreviewCompatibleImage festClassName="fest-image" imageInfo={this.props.data.image2} />
						<div className="fest-image-quote mb-sm-5">Everyone <span>is invited</span></div>
					</div>
					<div className="quote-image-wrapper">
						<PreviewCompatibleImage festClassName="fest-image" imageInfo={this.props.data.image3} />
						<div className="fest-image-quote mb-sm-5">Fashionably <span>on time</span></div>
					</div>
				</div>
			</div>
		);
	}
}

IndexPageTemplate.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

const IndexPage = ({data}) => {
	const { frontmatter } = data.markdownRemark;
	// console.log(frontmatter)
	return (
		<Layout pageTitle='Fest'>
			<IndexPageTemplate data={frontmatter}/>
		</Layout>
	)
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default IndexPage


export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        image1 {
					childImageSharp {
						gatsbyImageData(
							placeholder: NONE
							formats: [AUTO, WEBP, AVIF]
						)
					}
        }
				imageDescr1
				image2 {
					childImageSharp {
						gatsbyImageData(
							placeholder: NONE
							formats: [AUTO, WEBP, AVIF]
						)
					}
        }
				imageDescr2
				image3 {
					childImageSharp {
						gatsbyImageData(
							placeholder: NONE
							formats: [AUTO, WEBP, AVIF]
						)
					}
        }
				imageDescr3
      }
    }
  }
`;
